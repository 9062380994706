import React, { useEffect, useState, useRef, useMemo, useCallback } from 'react';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import { useAccount } from 'wagmi';
import { Network, Alchemy } from 'alchemy-sdk';

const settings = {
  apiKey: process.env.REACT_APP_ALCHEMY_API_KEY,
  network: Network.ETH_MAINNET,
};

const alchemy = new Alchemy(settings);
const TCPSC = process.env.REACT_APP_TCP_SC_ADDRESS;
const ZMSC = process.env.REACT_APP_ZM_SC_ADDRESS;
const HONORARYSC = process.env.REACT_APP_HONORARY_SC_ADDRESS;

export function Home() {
  const [chosenOne, setChosenOne] = useState({ id: null, value: '', name: null });
  const { address, isConnected } = useAccount();

  const [userNFTS, setUserNFTS] = useState([]);
  const [userNFTSLoaded, setUserNFTSLoaded] = useState(false);
  const [isReadyToDownload, setIsReadyToDownload] = useState(false);
  const canvasRef = useRef(null);

  // Removed useCallback and defined getUserNFTS inside useEffect
  useEffect(() => {
    const getUserNFTS = async () => {
      if (isConnected && address) {
        setUserNFTSLoaded(false);
        try {
          const nfts = await alchemy.nft.getNftsForOwner(address, {
            contractAddresses: [TCPSC, ZMSC, HONORARYSC],
          });
          setUserNFTS(nfts.ownedNfts);
          setUserNFTSLoaded(true);
          setChosenOne({ id: null, value: '', name: null });
        } catch (error) {
          console.error('Error during getting user NFTs', error);
        }
      }
    };

    getUserNFTS();
  }, [isConnected, address]); // Effect runs only when isConnected or address changes

  const updateCanvas = useCallback(() => {
    const canvas = canvasRef.current;
    if (!canvas || !chosenOne.value) return;
    const ctx = canvas.getContext('2d');
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    const img = new Image();
    img.crossOrigin = 'Anonymous';
    img.src = `https://assets.thecryptomasks.xyz/images/${chosenOne.name}/${chosenOne.id}.jpg?v=2`;
    img.onload = () => {
      ctx.drawImage(img, 0, 0, 469, 512);
    };
  }, [chosenOne]);

  useEffect(() => {
    updateCanvas();
    setIsReadyToDownload(false);
  }, [chosenOne, updateCanvas]);

  const durovize = useCallback(() => {
    const canvas = canvasRef.current;
    if (!canvas) return;
    const ctx = canvas.getContext('2d');

    const chosenImg = new Image();
    chosenImg.crossOrigin = 'Anonymous';
    chosenImg.src = `https://assets.thecryptomasks.xyz/images/${chosenOne.name}/${chosenOne.id}.jpg?v=2`;
    const halloweenMask = new Image();
    halloweenMask.src = '/images/halloween-layer.png';

    let opacity = 0;

    const fadeIn = () => {
      if (opacity < 0.2) {
        opacity += 0.001;
        ctx.globalAlpha = opacity;
        ctx.drawImage(halloweenMask, 0, 0, 469, 512);
        requestAnimationFrame(fadeIn);
      } else {
        ctx.globalAlpha = 1;
        ctx.drawImage(chosenImg, 0, 0, 469, 512);
        ctx.drawImage(halloweenMask, 0, 0, 469, 512);
        setIsReadyToDownload(true);
      }
    };

    chosenImg.onload = () => {
      halloweenMask.onload = () => {
        fadeIn();
      };
    };
  }, [chosenOne]);

  const downloadImage = useCallback(() => {
    const canvas = canvasRef.current;
    if (!canvas) return;
    const image = canvas.toDataURL('image/png');
    const link = document.createElement('a');
    link.href = image;
    link.download = 'cryptomasks-halloween-2024.png';
    link.click();
  }, []);

  const handleSelectChange = (e) => {
    const selectedOption = e.target.options[e.target.selectedIndex];
    const name = selectedOption.getAttribute('data-name');
    const id = selectedOption.getAttribute('data-id');
    setChosenOne({ name, id, value: e.target.value });
  };

  const userNFTSListbox = useMemo(() => {
    return (
      <Form.Select className="mb-3" onChange={handleSelectChange} value={chosenOne.value}>
        {!userNFTSLoaded ? (
          <option value="">Loading...</option>
        ) : (
          <>
            {userNFTS.length === 0 ? (
              <option value="" disabled>
                No Cryptomasks/Zodiacmasks in this wallet
              </option>
            ) : (
              <option value="">Please select a Cryptomasks/Zodiacmasks</option>
            )}
            {userNFTS.map((nft, index) => (
              <option
                key={index}
                value={`${nft.contract.name}-${nft.tokenId}`}
                data-id={nft.tokenId}
                data-name={nft.contract.name}
              >
                {nft.name}
              </option>
            ))}
          </>
        )}
      </Form.Select>
    );
  }, [userNFTS, userNFTSLoaded, chosenOne]);

  return (
    <>
      {isConnected && (
        <Container className="col-xl-10 col-xxl-8 px-4">
          <Row className="py-2">
            <Col md={7} className="mx-auto">
              <Form className="p-4 p-md-5 rounded-3 bg-dark">
                {userNFTSListbox}
                {chosenOne.id != null && !isReadyToDownload && (
                  <Button
                    variant="dark"
                    type="button"
                    onClick={durovize}
                    className="halloween-btn m-1"
                  >
                    Get the Halloween 2024 version of my NFT
                  </Button>
                )}
                {isReadyToDownload && (
                  <Button
                    variant="dark"
                    type="button"
                    onClick={downloadImage}
                    className="halloween-btn m-1"
                  >
                    Download my PFP
                  </Button>
                )}
                {chosenOne.id != null && (
                  <div className="canvas-container">
                    <canvas
                      ref={canvasRef}
                      width={469}
                      height={512}
                      className="mt-4"
                    ></canvas>
                  </div>
                )}
              </Form>
            </Col>
          </Row>
        </Container>
      )}
    </>
  );
}
